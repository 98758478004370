import { Link } from 'gatsby';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import styled from 'styled-components';

export const ProductCardContainer = styled.div`
  margin: 0 auto;
  width: 94%;
  text-decoration: none;
`;
export const ProductInnerContainer = styled.div`
  margin: 10px;
`;
export const ProductWrapper = styled.div`
  display: flex;
`;
export const ProductCards = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  //width: 250px;

  // @media screen and (min-width: 425px) {
  //   justify-content: center;
  // }
`;

export const ProductCard = styled.div`
  width:250px;
  //width:100%;
  overflow: hidden;
  text-align: left;
  margin: 10px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 30%);
  border-radius: 8px;
  padding: 10px;
  :hover {
    text-decoration: none;
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  }
`;
export const ImgContainer = styled.div`
display: none;

  @media screen and (min-width: 768px) {
    display: block;
  height: 165px;
  margin: 10px 0;
  width: 100%;
  position: relative;
  }
`;

export const ImgContainerM = styled.div`
  display: block;
  height: 165px;
  margin: 10px 0;
  width: 100%;
  position: relative;

  @media screen and (min-width: 768px) {
    display:none;
  }
`;

export const ProductImg = styled.img`
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;
export const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  padding: 10px;
`;
export const CounterContainer = styled.div`
  height: 27px;
`;
export const ProductName = styled.span`
  color: gray;
  height: 50px;
  font-size: 17px;
  margin-bottom: 5px;
  word-wrap: break-word;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-align: left;
`;
export const StoreContainer = styled.p`
  margin: 15px 0;
`;
export const NameTag = styled.span`
  color: #1d9682;
`;
export const StoreName = styled(Link)`
  font-size: 17px;
  color: blue;
  text-decoration: none;
`;
export const CartIcon = styled(AiOutlineShoppingCart)`
  width: 20px;
  height: 20px;
  margin-left: 5px;
`;
export const ProductSize = styled.span`
  font-size: 12px;
  color: #757575;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
`;
export const CardRatingContainer = styled.div`
  display: inline-block;
  font-size: 12px;
`;
export const CardRatingWrapper = styled.div`
  background-color: #1aab2a;
  color: #fff;
  font-size: 12px;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  padding: 1px 5px;
  cursor: pointer;
  position: relative;
  margin-right: 5px;
  height: 15px;
`;

export const RatingNumber = styled.span`
  font-weight: 700;
  display: inline-block;
  vertical-align: middle;
`;
export const StarIcon = styled.svg`
  width: 12px;
  height: 12px;
  margin-left: 2px;
  display: inline-block;
  vertical-align: middle;
`;
export const RatingDetailHeader = styled.span`
  display: inline-block;
  color: rgba(0, 0, 0, 0.58);
  margin-right: 5px;
  vertical-align: middle;
  cursor: pointer;
`;
export const PriceContainer = styled.div`
  height: 45px;
`;
export const KshTag = styled.span`
  display: inline-block;
  font-size: 12px;
  color: #757575;
  margin-right: 2px;
`;
export const ProductPrice = styled.span`
  display: inline-block;
  font-size: 12px;
  color: #757575;
  margin-right: 2px;
`;
export const DiscountPercent = styled.span`
  font-size: 12px;
  color: #1aab2a;
`;
export const FinalPrice = styled.span`
  display: inline-block;
  font-size: 16px;
  color: #212121;
`;
export const AddToCartBtn2 = styled.button`
  display: flex;
  align-items: center;
  border-radius: 8px;
  white-space: nowrap;
  color: #1d9682;
  font-size: 15px;
  outline: none;
  border: 2.5px solid #1d9682;
  cursor: pointer;
  padding: 5px 15px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  :hover {
    background: #2e4158;
    color: #e9ecef;
  }
`;
