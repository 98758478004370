import React from 'react';
import '../style/pharm.css';
import styled from 'styled-components';
import Slider from 'react-slick';
import { useStaticQuery, graphql } from 'gatsby';
import ShopHeader from '../components/header/ShopHeader';
import Footer from '../components/homeNav/footer/Footer';
import Med from '../components/med/Med';
import Seo from '../components/seo';
import Next from '../images/right-arrow.svg';
import Prev from '../images/left-arrow.svg';
import PageNav from '../components/homeNav/PageNav';

const ProductSlideWrapper = styled.div`
  padding: 1em 1.5em;
  margin: 1em 0;
  width: 99%;
  margin-right: auto;
  margin-left: auto;
`;

export default function Store({ location, pageContext }) {
  function SlickArrowLeft({ currentSlide, slideCount, ...props }) {
    return (
      <img
        src={Prev}
        alt="prevArrow"
        {...props}
        style={{ height: '40px', width: '40px' }}
      />
    );
  }

  function SlickArrowRight({ currentSlide, slideCount, ...props }) {
    return (
      <img
        src={Next}
        alt="nextArrow"
        {...props}
        style={{ height: '40px', width: '40px' }}
      />
    );
  }
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    lazyLoad: 'anticipated',
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const data2 = useStaticQuery(graphql`
    query ShopProdQuery {
      allProducts {
        edges {
          node {
            id
            productName
            batchNumber
            category
            sellingPrice
            customBrandName
            type
            expiry
            formulation
            genericName
            packSize
            prescribed
            strength
            supplier
            showProduct
            showPrice
            productImage
            score
            discount {
              type
              value
            }
            unit
            shop {
              name
              physicalAddress {
                county
                street
              }
            }
          }
        }
      }
    }
  `);

  const productsAll = data2.allProducts.edges.map((item) => item.node);
  const { shop } = pageContext;
  const products = pageContext.product;

  const keyword = shop.name
    ? `${shop.name.toLowerCase()
    } pharmacy ${shop?.physicalAddress?.street.toLowerCase()
    }, Kenya - Afyabook`
    : 'pharmacy in Kenya - Afyabook';
  const keyword2 = shop.name ? `${shop.name} in Kenya` : 'pharmacy in Kenya';
  const keyword4 = shop.name || 'pharmacy';
  const keyword_desc = shop.name
    ? `Want to order medication online or upload prescriptions to ${shop.name.toLowerCase()
    } pharmacy? Use Afyabook, pay and get your medications delivered`
    : 'Want to order medication online or upload prescriptions to pharmacies? Use Afyabook, pay and get your medications delivered';
  const siteurl = `https://www.afyabook.com/pharmacy/${shop.name?.replace(/ /g, '-').toLowerCase()}`;

  return (
    <>
      <Seo
        title={keyword}
        description={keyword_desc}
        keywords={keyword2 + keyword4}
        img="https://res.cloudinary.com/tripleaim-software/image/upload/v1656845131/afyabook%20images/drugplaceholderImg_mpt3rj.png"
        siteUrl={siteurl}
        canonical={siteurl}
      />
      <div className="main-content">
        <PageNav />
        <ShopHeader shop={shop} />
        <div className="container-fluid">
          {/* <div className="slideTitle">
            <div className="saleContainer">
              <span className="heading">Full body health checkups</span>
            </div>
            <span className="seeall">
              <button className="seeallBtn">SEE ALL</button>
            </span>
          </div> */}
          <div
            style={{
              margin: '10px 0 20px 0',
              textAlign: 'center',
              fontSize: '1.25em',
            }}
          >
            <div>
              {/* <ListItem /> */}
              {/* <div>About {shop.name}</div> */}
              {/* <div style={{ textDecoration: "underline" }}>Contacts</div>
              <div>Email: {shop.email}</div>
              <div>Phone: {shop.phone}</div> */}
              <div style={{ textDecoration: 'underline', marginTop: '5px' }}>
                Physical Store Location
              </div>
              <div>
                {shop.physicalAddress.county.charAt(0).toUpperCase()
                  + shop.physicalAddress.county.slice(1).toLowerCase()}
              </div>
              <div>
                {shop.physicalAddress.street.charAt(0).toUpperCase()
                  + shop.physicalAddress.street.slice(1).toLowerCase()}
              </div>
              <div>{shop.description}</div>
            </div>
          </div>
        </div>
        {/* <div className="container-fluid" style={{ margin: "0", padding: "0" }}>
          <div className="slideTitle">
            <div className="saleContainer">
              <span className="heading">{shop.name.charAt(0).toUpperCase() + shop.name.slice(1).toLowerCase()}
                {" "}
                {shop.location.charAt(0).toUpperCase() + shop.location.slice(1).toLowerCase()}{", "}
                {" "}
                {shop.streetLocation.charAt(0).toUpperCase() + shop.streetLocation.slice(1).toLowerCase()}</span>
            </div>
            <span className="seeall">
              <TopButton to="/Search/">More</TopButton>
            </span>
          </div>
        </div> */}
        <div>
          <div>
            <h5 style={{ marginLeft: '10px' }}>Offers</h5>
            <ProductSlideWrapper>
              <Slider {...settings}>
                {productsAll.map((product) => (
                  product.showProduct
                  && !product.prescribed
                  && product.shop.name == shop.name
                  && product.category.includes('offers') && (
                    <Med products={product} />
                  )
                ))}
              </Slider>
            </ProductSlideWrapper>
          </div>

          <div>
            <h5 style={{ marginLeft: '10px' }}>Personal Care</h5>
            <ProductSlideWrapper>
              <Slider {...settings}>
                {productsAll.map((product) => (
                  product.showProduct
                  && product.shop.name == shop.name
                  && product.category.includes('personal care') && (
                    <Med products={product} />
                  )
                ))}
              </Slider>
            </ProductSlideWrapper>
          </div>

          <div>
            <h5 style={{ marginLeft: '10px' }}>Mum and Baby</h5>
            <ProductSlideWrapper>
              <Slider {...settings}>
                {productsAll.map((product) => (
                  product.showProduct
                  && product.shop.name == shop.name
                  && product.category.includes('mum and baby') && (
                    <Med products={product} />
                  )
                ))}
              </Slider>
            </ProductSlideWrapper>
          </div>

          <div>
            <h5 style={{ marginLeft: '10px' }}>Vitamins & Suppliments</h5>
            <ProductSlideWrapper>
              <Slider {...settings}>
                {productsAll.map((product) => (
                  product.showProduct
                  && product.shop.name == shop.name
                  && product.category.includes('vitamins & suppliments') && (
                    <Med products={product} />
                  )
                ))}
              </Slider>
            </ProductSlideWrapper>
          </div>

          <div>
            <h5 style={{ marginLeft: '10px' }}>Beauty & Skin care</h5>
            <ProductSlideWrapper>
              <Slider {...settings}>
                {productsAll.map((product) => (
                  product.showProduct
                  && product.shop.name == shop.name
                  && product.category.includes('beauty and skin care') && (
                    <Med products={product} />
                  )
                ))}
              </Slider>
            </ProductSlideWrapper>
          </div>

          <div>
            <h5 style={{ marginLeft: '10px' }}>Sexual Health</h5>
            <ProductSlideWrapper>
              <Slider {...settings}>
                {productsAll.map((product) => (
                  product.showProduct
                  && product.shop.name == shop.name
                  && product.category.includes('sexual health') && (
                    <Med products={product} />
                  )
                ))}
              </Slider>
            </ProductSlideWrapper>
          </div>

          <div>
            <h5 style={{ marginLeft: '10px' }}>Family planning</h5>
            <ProductSlideWrapper>
              <Slider {...settings}>
                {productsAll.map((product) => (
                  product.showProduct
                  && product.shop.name == shop.name
                  && product.category.includes('family planning') && (
                    <Med products={product} />
                  )
                ))}
              </Slider>
            </ProductSlideWrapper>
          </div>

          <div>
            <h5 style={{ marginLeft: '10px' }}>Medical devices</h5>
            <ProductSlideWrapper>
              <Slider {...settings}>
                {productsAll.map((product) => (
                  product.showProduct
                  && product.shop.name == shop.name
                  && product.category.includes('medical devices') && (
                    <Med products={product} />
                  )
                ))}
              </Slider>
            </ProductSlideWrapper>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
