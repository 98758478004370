import React from "react"
import {
  AddToCartBtn2,
  ImgContainer,
  ImgContainerM,
  NameTag,
  StoreName,
  ProductCard,
  ProductCards,
  ProductDetails,
  ProductImg,
  ProductName,
  StoreContainer,
  CartIcon,
} from "./medElements"
import Drugicon from "../../images/medicineicon.png"
import { navigate } from "gatsby"
import useAuthState from "../../stores/auth"
import useCartState from "../../stores/cart"
import { toast } from "react-toastify"

export default function Med({ products }) {
  const { user } = useAuthState()

  const [success, setSuccess] = React.useState("")

  const { cart, setCart, increment } = useCartState()

  const addToCart = () => {
    const newCartProduct = {
      id: products.id,
      productName: products.productName,
      discount: products.discount,
      packSize: products.packSize,
      shop: products.shop.name,
      type: products.type,
      sellingPrice: products.sellingPrice,
      showPrice: products.showPrice,
      customBrandName: products.customBrandName,
      unit: products.unit,
      image: products?.productImage?.replace(
        "https://res.cloudinary.com/tripleaim-software/image/upload/",
        "https://res.cloudinary.com/tripleaim-software/image/upload/c_scale,w_230,f_auto/"
      ),
      quantity: 1,
    }

    /* if (cloudinaryImage) {
      newCartProduct.prescription = cloudinaryImage.secure_url
    } */

    const cartIndex = cart.findIndex(item => item.id === products.id)

    if (cartIndex === -1) {
      setCart([...cart, newCartProduct])
      toast.success(`Item added: ${newCartProduct.productName}`)
    } else {
      increment(cartIndex)
      toast.success(
        `You know have ${cart[cartIndex].quantity}  ${newCartProduct.productName} in your cart `
      )
    }
  }

  const image = products.productImage || Drugicon
  const imageM =
    products.productImage?.replace(
      "https://res.cloudinary.com/tripleaim-software/image/upload/",
      "https://res.cloudinary.com/tripleaim-software/image/upload/c_scale,w_184,f_auto/"
    ) || Drugicon

  const imageDesk =
    products.productImage?.replace(
      "https://res.cloudinary.com/tripleaim-software/image/upload/",
      "https://res.cloudinary.com/tripleaim-software/image/upload/c_scale,w_230,f_auto/"
    ) || Drugicon

  const goToProduct = (productName, productId) => {
    navigate(
      `/products/${productName.replace(/ /g, "-").toLowerCase()}/${productId}`
    )
  }
  return (
    <ProductCards>
      <ProductCard>
        {!!products.discount.value && (
          <div style={{ color: "green", fontSize: "1.5rem" }}>
            -{products.discount.value}% Off
          </div>
        )}
        <div
          onClick={() => goToProduct(products.productName, products.id)}
          style={{ cursor: "pointer" }}
        >
          <ImgContainer>
            <ProductImg alt="Product Image" src={imageDesk} />
          </ImgContainer>
          <ImgContainerM>
            <ProductImg alt="Product Image" src={imageM} />
          </ImgContainerM>

          <ProductDetails>
            <ProductName>
              {products.type === "pharmaceutical"
                ? products.customBrandName.charAt(0).toUpperCase() +
                  products.customBrandName.slice(1).toLowerCase()
                : products.productName.charAt(0).toUpperCase() +
                  products.productName.slice(1).toLowerCase() +
                  " " +
                  products.packSize}
            </ProductName>
            {products.type === "pharmaceutical" && (
              <NameTag>{products.genericName}</NameTag>
            )}

            {!!products.discount.value ? (
              <NameTag style={{ marginTop: "5px" }}>
                Price:{" "}
                <span style={{ textDecoration: "line-through", color: "gray" }}>
                  Ksh {products.sellingPrice}{" "}
                </span>
                Ksh{" "}
                {Math.round(
                  products.sellingPrice *
                    ((100 - products.discount.value) / 100)
                )}{" "}
              </NameTag>
            ) : (
              <NameTag style={{ marginTop: "5px", color: "black" }}>
                Price: Ksh{" "}
                {products.showPrice ? (
                  products.sellingPrice
                ) : (
                  <span>On request</span>
                )}
              </NameTag>
            )}
            <NameTag>Per: {products.unit}</NameTag>
          </ProductDetails>
        </div>
        {user?.admin && (
          <StoreContainer>
            <StoreName
              to={`/pharmacy/${products.shop.name
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
              {products.shop.name.charAt(0).toUpperCase() +
                products.shop.name.slice(1).toLowerCase() +
                " - " +
                products.shop.physicalAddress.street.charAt(0).toUpperCase() +
                products.shop.physicalAddress.street.slice(1).toLowerCase()}
            </StoreName>{" "}
          </StoreContainer>
        )}
        {/* <StoreContainer>
          <StoreName
            to={`/pharmacy/${products.shop.name.replace(/ /g, "-").toLowerCase()}`}
          >
            {products.shop.physicalAddress.street.charAt(0).toUpperCase() + products.shop.physicalAddress.street.slice(1).toLowerCase()}
          </StoreName>{" "}
        </StoreContainer> */}
        <div style={{ width: "100%" }}>
          {products.prescribed ? (
            <AddToCartBtn2
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() =>
                navigate(
                  `/products/${products.productName
                    .replace(/ /g, "-")
                    .toLowerCase()}/${products.id}`
                )
              }
            >
              {" "}
              Prescription{" "}
            </AddToCartBtn2>
          ) : (
            <AddToCartBtn2
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => addToCart()}
            >
              {" "}
              Add to cart <CartIcon />{" "}
            </AddToCartBtn2>
          )}
          <AddToCartBtn2
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
            onClick={() =>
              navigate(
                `/products/${products.productName
                  .replace(/ /g, "-")
                  .toLowerCase()}/${products.id}`
              )
            }
          >
            {" "}
            Details{" "}
          </AddToCartBtn2>
        </div>
      </ProductCard>
    </ProductCards>
  )
}
