import React, { useState } from "react"
import styled from "styled-components"
import { desktop, mobileS } from "../../responsive"
import BannerImg from "../../images/pharmacybunner.webp"
import { navigate } from "gatsby"
import useAuthState from "../../stores/auth"

const HeaderContainer = styled.div`
  align-items: center;

  background-image: url(${BannerImg});
  background-position: center;
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;
  padding: 0.5rem 1rem;
  text-align: center;
`
const HeaderTitle = styled.p`
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 55px;
  color: white;
  backdrop-filter: blur(3px);
  padding: 1em;
  border-radius: 4px;
  background-color: #2e41588f;
  color: white;
  ${mobileS({
    fontWeight: "200",
    fontSize: "1.5em",
    lineHeight: "1.3em",
  })}
  ${desktop({
    fontWeight: "200",
    fontSize: "2em",
    lineHeight: "1.3em",
  })}
`

export default function Header({ shop }) {
  const { user } = useAuthState()

  const [firstName, setFirstName] = useState(user?.firstName || "")

  const [query, setQuery] = useState("")

  React.useEffect(() => {
    if (query) {
      navigate(`/Search`, { state: { query } })
    }
  }, [query])
  return (
    <HeaderContainer>
      <HeaderTitle>
        Hello there {firstName}, welcome to {shop.name} Pharmacy
        <br />
        Take a look at their products
      </HeaderTitle>
    </HeaderContainer>
  )
}
